@import "bootstrap/grid";

html,body{
    overflow-x: hidden;
}
.pull-left{
    float: left;
}
.pull-right{
    float: right;
}
.clearfix{
    clear: both;
}
ul.default{
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.img-responsive{
    @extend .col-sm-12;
    padding: 0px;
}
.stretch{
    align-items: stretch;
    //justify-content: center;
    //align-items: center;
}
.alert{
    @extend .col-sm-12;
    background-color: #5cb100;
    border-radius: 8px;
    font-size: 13px;
    p{
        padding: 10px;
        color: white;
        text-align: center;
        font-weight: bold;
    }
}
.whiteBg{
    padding-top: 30px;
    padding-bottom: 30px;
}
.mobileNav{
    position: absolute;
    top: 28px;
    right: 15px;
    display: none;
    @media screen and (max-width:989px){
        display: block;
    }
}
body{
    margin: 0px;
    padding: 0px;
    .page{
        padding-top: 50px;
        padding-bottom: 50px;
        h2{
            font-size: 30px;
            line-height: 45px;
        }
        h3{
            color: black;
            font-size: 25px;
            line-height: 30px;
            padding: 0px;
            margin: 0px;
        }

        ul{
            list-style: none;
            -webkit-margin-before: 0px;
            margin-block-start: 0px;
            -webkit-margin-after: 0px;
            margin-block-end: 0px;
            -webkit-margin-start: 0;
            margin-inline-start: 0;
            -webkit-margin-end: 0;
            margin-inline-end: 0;
            -webkit-padding-start: 0px;
            padding-inline-start: 0px;
            li{
                position: relative;
                padding-left: 30px;
            }
            li::before{
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background-color: #bf1522;
                border-radius: 100%;
                position: absolute;
                left: 10px;
                top: 10px;
            }
        }
        .contact-block{
            @extend .col-lg-12;
            @extend .col-xl-6;
        }
        .contact-map-block{
            @extend .col-lg-12;
            @extend .col-xl-6;
        }
        .contact,.contacts{
            @extend .col-sm-12;
            @extend .col-md-6;
            margin-bottom: 60px;
            font-size: 15px;
            line-height: 22px;
            span{
                min-width: 90px;
                display: inline-block;
                font-weight: bold;
                line-height: 30px;
                &.person{
                    min-width: 180px;
                }
            }
            a{
                color: #bf1522;
                text-decoration: none;
            }
        }
        .contacts{
            @extend .col-md-12;
        }
        .table{
            @extend .col-sm-12;
            @extend .col-md-8;
            @extend .offset-md-2;
            margin-bottom: 40px;
            h2{
                margin: 0px;
                padding: 0px;
                line-height: 30px;
            }
            ul{
                display: table;
                width: 100%;
                padding: 5px;
                &:nth-child(odd){
                    background-color: white;
                    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
                    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
                    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
                }
                li{
                    padding: 5px;
                    display: table-cell;
                    small{
                        font-weight: bold;
                        color: black;
                    }
                    &:nth-of-type(2),&:nth-of-type(3) {
                      text-align: right;
                      width: 160px;
                      font-weight: bold;
                      font-size: 15px;
                    }
                    &.full{
                        small{
                            font-size: 13px;
                            font-weight: normal !important;
                        }
                    }
                }
                li::before{
                    display: none;
                }
                &:first-of-type{
                    li{
                        text-align: right;
                    }
                }
                @media screen and (max-width:534px){
                    &{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        li:nth-of-type(1){
                            width: 100% !important;
                            flex: 0 100%;
                            min-width: 100%;
                            text-align: center;
                            //display: inline-block;
                        }
                        li:nth-of-type(2),li:nth-of-type(3){
                            flex: 0 50%;
                            min-width: 50%;
                            text-align: center;
                            padding-top: 15px;
                            padding-bottom: 15px;
                        }


                    }
                }
            }
        }
        .row{
            .service{
                @extend .col-sm-6;
                @extend .col-md-4;
                margin-bottom: 40px;
                h3{
                    font-size: 25px;
                    line-height: 30px;
                    margin: 0px;
                    padding: 0px;
                    i{
                        color: #bf1522;
                        margin-right: 10px;
                    }
                }
                p{
                    font-size: 16px;
                }
            }
            .text-full{
                @extend .col-sm-12;
                margin-bottom: 40px;
            }
            .text{
                @extend .col-sm-6;
                margin-bottom: 40px;
                padding-right: 60px;

                &:last-of-type{
                    padding-right: 0px;
                    //@extend .offset-sm-2;
                }
                p{
                    i{
                        color: #bf1522;
                        margin-right: 15px;
                        font-size: 30px;
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }
        .service-ico{
            @extend .col-sm-6;
            @extend .col-md-4;
            @extend .col-lg-3;
            p{
                i{
                    color: #bf1522;
                    margin-right: 15px;
                    font-size: 30px;
                    position: relative;
                    top: 5px;
                }
            }
        }

        .text{
            @extend .col-sm-6;

        }
        .text-img{
            @extend .col-md-7;
        }
        .img{
            @extend .col-sm-12;
            @extend .col-md-5;
            span{
                background-color: white;
                //max-height: 300px;
                -webkit-box-shadow: 0px 0px 43px 0px rgba(126,126,126,.18);
                -moz-box-shadow: 0px 0px 43px 0px rgba(126,126,126,.18);
                box-shadow: 0px 0px 43px 0px rgba(126,126,126,.18);
                text-align: center;
                padding: 5%;
                display: block;
                margin-bottom: 15px;
            }
            img{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    #headline{
        background-color: #bf1522;
        color: white;
        padding-top: 50px;
        padding-bottom: 50px;
        .content{
            @extend .col-sm-12;
        }
        h1{
            color: white;
            text-align: center;
            display: block;
            text-transform: uppercase;
        }
    }
    #head{
        .head{
            @extend .col-sm-12;
            a.logo{
                @extend .pull-left;
                margin-top: 25px;
                img{
                    max-width: 240px;
                }
                @media screen and (max-width:1421px){
                    &{
                        margin-top: 30px;
                        img{
                            max-width: 200px;
                        }
                    }
                }
                @media screen and (max-width:1100px){
                    img{
                        max-width: 180px;
                        margin-top: 0px;
                    }
                }
            }
            ul{
                @extend .pull-right;
                @extend ul.default;
                padding-top: 41px;
                padding-bottom: 41px;
                li{
                    a{
                        margin-left: 25px;
                    }
                    &:first-of-type{
                        a{
                            margin-left: 0px;
                        }
                    }
                }
            }
            @media screen and (max-width:1136px){
                ul{
                    li{
                        a{
                            margin-left: 10px;
                        }
                    }
                }
            }
            @media screen and (max-width:989px){
                &{
                    padding-bottom: 20px;
                }
                ul{
                    display: none;
                    width: 100%;
                    padding: 20px 0px 0px 0px;
                    li{
                        display: block;
                        a{
                            padding: 10px 0px 10px 0px;
                            margin-left: 0px;
                            &:hover,&.active{
                                border: none !important;
                            }
                        }

                    }
                    &.active{
                        display: block;
                    }
                }
            }
        }
    }
    #slider{
        .slider-content{
            position: absolute;
            top: 0px;
            width: 100%;
            left: 0px;
            top: 0px;
            z-index: 999;
            height: 100%;
        }
        .container{
            overflow: hidden;
        }
        .owl-item{
            overflow: hidden;
            position: relative;
        }
        img{
            z-index: 3;
            position: relative;
        }
        .text{
            background-color: #c0202f;
            height: 100%;
            @extend .col-md-5;
            color: white;
            position: absolute;
            height: 100%;
            z-index: 4;
            padding-right: 0px;

            &::before{
                content: "";
                width: 50%;
                height: 100%;
                background-color: #c0202f;
                left: -50%;
                top: 0px;
                display: block;
                position: absolute;
                z-index: -1;
            }
            &::after{
                content: "";
                display: block;
                background-color: #c0202f;
                width: 100px;
                height: 120%;
                top: -10%;
                right: -50px;
                position: absolute;
                transform: rotate(10deg);
                z-index: 1;
            }

            & > div{
                align-self:center;
                @extend .col-md-12;
                margin-top: 10%;
                z-index: 10;
                position: relative;
            }
            img{
                width: auto;
            }
            h2{
                font-size: 35px;
                color: white;
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 20px;
                font-size: 17px;
                line-height: 23px;
                strong{
                    color: white;
                }
            }
            a.btn{
                margin-top: 15px;
                padding: 10px 20px 10px 20px;
                background-color: white;
                color: #c0202f;
                i{
                    margin-left: 5px;

                }
            }
            @media screen and (min-width:1400px) {
                h2{
                    margin-bottom: 20px;
                }
                p{
                    line-height: 26px;
                    margin-bottom: 40px;
                }
            }
        }
        .owl-item:nth-child(4),.owl-item:nth-child(6),.owl-item:nth-child(8){
            .text{
                background-color: #3d3d3d;
                &::after{
                    background-color: #3d3d3d;
                }
                &::before{
                    background-color: #3d3d3d;
                }
                @media screen and (max-width:980px){
                    &{
                        background: rgb(248,248,251);
                        background: -moz-linear-gradient(0deg, rgba(248,248,251,1) 30%, rgba(248,248,251,0) 100%);
                        background: -webkit-linear-gradient(0deg, rgba(248,248,251,1) 30%, rgba(248,248,251,0) 100%);
                        background: linear-gradient(0deg, rgba(248,248,251,1) 30%, rgba(248,248,251,0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f8fb",endColorstr="#f8f8fb",GradientType=1);
                    }
                }
            }
        }
        @media screen and (max-width:980px){
            .slider-content{
                bottom: 0px;
                top: auto;
                height: auto;
            }
            .container{
                padding: 0px;
            }
            .text{
                //max-width: 100% !important;
                margin-left: 0px;
                text-align: center;
                bottom: 0px;
                padding-bottom: 15px;
                background: none;
                background: rgb(248,248,251);
                background: -moz-linear-gradient(0deg, rgba(248,248,251,1) 30%, rgba(248,248,251,0) 100%);
                background: -webkit-linear-gradient(0deg, rgba(248,248,251,1) 30%, rgba(248,248,251,0) 100%);
                background: linear-gradient(0deg, rgba(248,248,251,1) 30%, rgba(248,248,251,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f8fb",endColorstr="#f8f8fb",GradientType=1);
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 50px;
                padding-bottom: 50px;
                height: 100%;
                display: block;
                position: static;
                min-width: 100%;
                &::after,&::before{
                    display: none;
                }
                & > div{
                    top: auto !important;
                    bottom: 5px !important;
                    //position: absolute;
                    width: 100% !important;
                    flex:0 100% !important;

                    left: 0px;
                    padding: 75px 15px 0px 15px;
                    max-width: 100%;
                    margin: 0px !important;
                    color: black;
                    background: none;
                    h2{
                        color: #c0202f;
                        font-size: 30px;
                        margin-bottom: 0px;
                    }
                    a.btn{
                        background-color: #c0202f;
                        color: white;
                    }
                    p{
                        font-size: 14px;
                    }
                    img{
                        width: 100px;
                    }
                    strong{
                        color: black !important;
                    }
                }
                img{
                    margin-left: auto;
                    margin-right: auto;
                }
                p{
                    font-size: 15px;
                }
            }
        }
        .owl-nav{
            display: none;
        }
        .owl-dots{
            position: absolute;
            left:25px;
            top: 40%;
            z-index: 100;
            div{
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: white;
                margin-bottom: 10px;
                &.active{
                    background: none;
                    border: 2px solid white;
                    width: 14px;
                    height: 14px;
                    margin-left: -2px;
                    position: relative;
                }
            }
        }
    }
    .welcome{
        .row{
            @extend .align-items-center;

        }
        .text{
            @extend .col-md-12;
            @extend .col-lg-7;
            padding: 60px 15px 60px 15px;
        }
        .img{
            @extend .col-12;
            @extend .col-md-12;
            @extend .col-lg-5;

            & > div{
                @extend .row;
                & > span{
                    @extend .col-12;
                    @extend .col-sm-6;
                    padding:15px;
                    & > span{
                        background-color: white;
                        max-height: 300px;
                        -webkit-box-shadow: 0px 0px 43px 0px rgba(126,126,126,.18);
                        -moz-box-shadow: 0px 0px 43px 0px rgba(126,126,126,.18);
                        box-shadow: 0px 0px 43px 0px rgba(126,126,126,.18);
                        text-align: center;
                        padding: 5%;
                        display: block;
                    }
                    img{
                        width: 80%;
                        height: auto !important;
                        margin-left: auto;
                        margin-right: auto;
                        @extend .col-md-12;
                    }
                    &:last-of-type{
                        @extend .col-sm-12;
                    }
                }
            }

        }
    }
    .title{
        @extend .col-sm-12;
        h2{
            @extend .pull-left;
        }
        a{
            @extend .pull-right;
            position: relative;
            top: 22px;
        }
        @media screen and (max-width:698px){
            a{
                display: none;
            }
        }
        @media screen and (max-width:575px){

        }
    }
    .services{

        .service{
            @extend .col-sm-4;
            @extend .col-md-4;
            @extend .col-lg-3;
            margin-bottom: 25px;
            .row{
                @extend .align-items-center;
                .icons{
                    @extend .col-2;
                    @extend .col-sm-2;
                }
                .text{
                    @extend .col-10;
                    @extend .col-sm-10;
                }
            }
        }
    }
    #contacts{
        .contactForm{
            @extend .col-lg-6;
            @extend .col-xl-5;
            @extend .offset-xl-1;
            @extend .col-md-12;
            @extend .col-sm-12;
            padding-top: 15px;
            padding-bottom: 25px;
            padding-right: 15px;
            .form-group{
                @extend .col-sm-6;
                &.textarea,&.btns,&.checkbox{
                    @extend .col-sm-12;
                }
                input:not([type='checkbox']),textarea{
                    width: 100%;
                    padding: 15px 10px 15px 10px;
                }
                textarea{
                    height: 120px;
                }
                .btn{
                    width: 100%;
                    display: block;
                    cursor: pointer;
                }
                &.checkbox{
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 15px;
                    label{
                        padding-left: 50px;
                        display: block;
                    }
                    .icheckbox_square-blue{
                        position: absolute;
                        margin-left: 10px;
                        margin-top: 4px;
                    }
                }
            }
        }
        .contact{
            @extend .col-lg-6;
            @extend .col-xl-5;
            @extend .col-md-12;
            @extend .col-sm-12;
            padding-bottom: 20px;
            padding-top: 15px;
            padding-left: 15px;
            .block{
                @extend .col-sm-12;
            }
            .cont{
                @extend .col-sm-6;
            }
        }
        @media screen and (min-width:1050px){
            .contact{
                padding-left: 45px;
            }
            .contactForm{
                padding-right: 45px;
            }
        }
    }
    .map{
        width: 100%;
        height: 300px;
    }
    #partners{
        width: 100%;
        .title{
            @extend .col-sm-12;
            text-align: center;
        }
        .partner{
            width: calc(100% / 9);
            padding: 15px;
            img{
                @extend .img-responsive;
            }
            @media screen and (max-width:783px) {
                &{
                    width: calc(100% / 4);
                }
            }
            @media screen and (max-width:330px) {
                &{
                    width: calc(100% / 2);
                }
            }
        }
    }
}
@media screen and (min-width:1400px) and (max-width:1700px) {
    .container{
        width: 100%;
        max-width: 100%;
        padding-left: 150px;
        padding-right: 150px;
    }
}
@media screen and (min-width:1000px) and (max-width:1400px) {
    .container{
        width: 100%;
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media screen and (min-width:576px) and (max-width:1000px){
    .container{
        max-width: 100%;
        padding-left: 25px;
        padding-right: 25px;
    }
}
